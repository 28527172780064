import jeanPhilippeDelberghe from '../images/jean-philippe-delberghe.jpg';
import imgRow1 from '../images/img-row-01.jpg';
import imgRow2 from '../images/img-row-02.jpg';
import imgRow3 from '../images/img-row-03.jpg';
import imgRow4 from '../images/img-row-04.jpg';
import imgRow5 from '../images/img-row-05.jpg';
import imgRow6 from '../images/img-row-06.jpg';
import imgRow7 from '../images/img-row-07.jpg';
import imgRow8 from '../images/img-row-08.jpg';
import favicon from '../images/favicon.ico';
import logo from '../images/konnerud-panorama-logo.svg';
import partners from '../images/partner.png';
import facebook from '../images/facebook.jpg';

const images = {
    jeanPhilippeDelberghe,
    imgRow1,
    imgRow2,
    imgRow3,
    imgRow4,
    imgRow5,
    imgRow6,
    imgRow7,
    imgRow8,
    favicon,
    logo,
    partners,
    facebook
}
export default images;